<template>
  <div
    class="relative"
    :class="{
      'text-alert-error-stroke font-bold': errorMessage && _isEmpty(currValue) && !errorTooltip
    }"
  >
    <atoms-tooltip
      v-model="showError"
      class="absolute -top-10 left-4 !z-[2]"
      type="error"
      size="small"
      tip-x="left"
    >
      {{ errorMessage }}
    </atoms-tooltip>

    <ul
      class="relative"
      :class="[
        component.wrapper,
        listWrapperClass
      ]"
    >
      <li
        v-for="(option, optionIndex) in optionArr"
        :key="optionIndex"
        ref="checkbox_clickable_li"
        class="relative align-top rounded-lg text-inherit"
        :class="[
          component.display,
          component.padding,
          `${direction === 'row' && (option && (option.info || option.icon) ? 'pr-[0.625rem]' : '')}`,
          {
            'is-checked': isSelected(option),
            'is-disabled cursor-not-allowed': option && option.disabled,
            'cursor-pointer': option && !option.disabled,
            '!p-0 min-w-fit': noBgSelectionBox
          }
        ]"
        @click="handleSelectOption(option)"
      >
        <input
          v-bind="$attrs"
          :id="handleId(option.label || option)"
          ref="input"
          :label="removeHtmlString(option.label || option).replace(/[\r\n]/gm, '').replace(/\s+/gm, ' ').trim()"
          :value="option[dataValue] || option"
          :name="handleInputName(option)"
          :type="type"
          :checked="isSelected(option)"
          :disabled="option && option.disabled"
          class="peer appearance-none col-start-1 row-start-1"
          :class="[
            {
              'hidden': ticker === 'none'
            }
          ]"
        />

        <div
          ref="selection-box"
          class="selection-box absolute top-0 left-0 w-full h-full bg-white"
          :class="[
            component.active,
            {
              'rounded-lg border border-gray-400': !noBox,
              '!border-[#d5d5d5]': !noBox && option && option.disabled,
              'hover:border hover:border-success hover:shadow-md z-10 !bg-transparent peer-checked:shadow-md peer-checked:border peer-checked:border-success peer-checked:!bg-transparent': ticker === 'icon',
              '!bg-transparent peer-checked:!bg-transparent': noBgSelectionBox
            }
          ]"
        >
        </div>

        <div
          v-if="ticker !== 'none'"
          ref="ticker"
          class="relative overflow-hidden flex place-items-center justify-center border border-gray-400 peer-checked:bg-dark peer-checked:border-2 peer-checked:border-white before:content-[''] before:absolute before:bg-[url('/icons/check.svg')] before:bg-no-repeat before:bg-center before:hidden peer-checked:before:block"
          :class="[
            component.position.box,
            component.ticker,
            component.tickerSize,
            {
              '!border-gray-350 !bg-gray-350': option && option.disabled,
              'peer-checked:border-dark mb-auto': size === 'xs',
              '!place-self-start': ['xs', 'sm-compact'].includes(size),
              '!min-h-[40px] !min-w-[40px]  before:!bg-none peer-checked:!bg-transparent': ticker === 'icon',
              'bg-white': noBgSelectionBox
            }
          ]"
        >
          <NuxtImg
            v-if="ticker === 'icon' && option.tickerIcon"
            :src="`/icons/${option.tickerIcon}.svg`"
            :alt="option.label || option"
            width="40"
            height="40"
          />
        </div>

        <div
          ref="label"
          class="flex place-items-center grow z-[1]"
          :class="[
            component.label,
            component.position.label,
            handleIconMargin(option),
            option && (option.icon || option.info) ? '' : '',
            {
              'self-center': option.icon,
              '!text-[#a3a4a8]': option && option.disabled,
              'text-xs': size === 'xs',
              'text-[14px]': size === 'sm-compact'
            }
          ]"
        >
          <!-- eslint-disable vue/no-v-html -->
          <div :class="[{'text-sm': props.theme === 'light'}, {'w-full': props.ticker === 'none'}]" v-html="(option?.label ?? option)"></div>
        </div>

        <div
          ref="tooltipWrapper"
          class="tooltip flex relative items-center"
          :class="[
            component.position.icon,
            direction === 'col' ? 'w-full justify-center' : ''
          ]"
        >
          <component
            :is="option.icon"
            v-if="option.icon"
            ref="icon"
            width="40"
            height="40"
          />

          <div
            v-if="!_isEmpty(option && option.info) && checkboxMap[`options-${optionIndex}`]"
            class="relative"
            :class="direction === 'col' ? 'flex w-full justify-center' : ''"
          >
            <IconsInfoCircle
              v-if="!_isEmpty(option && option.info)"
              ref="info-icon"
              type="qs-mark-outline"
              :fill="handleInfoColors(option)"
              @click.stop="handleShowTooltip($event, optionIndex)"
              @mouseover.stop="handleShowTooltip($event, optionIndex)"
              @mouseout.stop="handleShowTooltip($event, optionIndex, false)"
            />

            <atoms-tooltip
              v-show="
                !_isEmpty(option && option.info) &&
                  checkboxMap[`options-${optionIndex}`]?.show
              "
              v-model="checkboxMap[`options-${optionIndex}`].show"
              type="info"
              class="absolute w-[18.125rem] max-w-[18.125rem] z-[3]"
              :class="[
                `options-${optionIndex}`,
                handleTooltipPosition()
              ]"
              :tip-x="
                direction === 'row'
                  ? checkboxMap[`options-${optionIndex}`].tipX
                  : 'center'
              "
              :tip-y="checkboxMap[`options-${optionIndex}`].tipY"
              @click.stop
            >
              <template
                v-if="option.info && option.info.title"
                #title
              >
                {{ option.info.title }}
              </template>

              {{ option.info.content }}
            </atoms-tooltip>
          </div>
        </div>
      </li>
    </ul>

    <atoms-button
      v-if="listLimit > 0"
      size="medium"
      theme="tertiary"
      class="hover:!text-dark mx-auto mt-4"
      :full="false"
      :enable-tracking="false"
      @click="showMore = !showMore"
    >
      View {{ showMore ? 'less' : 'more' }}

      <IconsChevron
        class="ml-3"
        :direction="showMore ? 'top' : 'bottom'"
        type="circle"
        size="md"
      />
    </atoms-button>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'MoleculesSelection'
})

const props = defineProps({
  modelValue: {
    type: [String, Array, Object],
    default: ''
  },

  // for radio buttons
  name: {
    type: String,
    default: ''
  },

  size: {
    type: String,
    default: 'base',
    validator: value => ['xs', 'sm', 'base', 'sm-compact'].includes(value)
  },

  type: {
    type: String,
    default: 'checkbox',
    validator: value => ['checkbox', 'radio'].includes(value)
  },

  ticker: {
    type: String,
    default: 'square',
    validator: value => ['circle', 'square', 'icon', 'none'].includes(value)
  },

  theme: {
    type: String,
    default: 'secondary',
    validator: value => ['primary', 'secondary', 'tertiary', 'light'].includes(value)
  },

  direction: {
    type: String,
    default: 'row',
    validator: value => ['col', 'row'].includes(value)
  },

  options: {
    type: Array,
    default: () => [
      {
        value: 1,
        label: 'one',
        name: 'one',
        info: {
          title: 'one',
          content: 'one one one'
        },
        disabled: true
      },
      {
        value: 2,
        label: 'two',
        name: 'two',
        icon: 'IconsInfoCircle'
      },
      {
        value: 2,
        label: 'two',
        name: 'two',
        icon: 'IconsInfoCircle'
      }
    ]
  },

  dataValue: {
    type: String,
    default: 'value'
  },

  inline: {
    type: Boolean,
    default: false
  },

  switchIcon: {
    type: Boolean,
    default: false
  },

  cols: {
    type: Number,
    default: 1
  },

  mobileCols: {
    type: Number,
    default: 1
  },

  errorMessage: {
    type: String,
    default: ''
  },

  infoShowMultiple: {
    type: Boolean,
    default: false
  },

  infoShowAction: {
    type: String,
    default: 'click',
    validator: value => ['click', 'hover'].includes(value)
  },

  noBox: {
    type: Boolean,
    default: false
  },

  errorTooltip: {
    type: Boolean,
    default: true
  },

  noBoxGap: {
    type: String,
    default: ''
  },

  activeTextBold: {
    type: Boolean,
    default: true
  },

  tickerPosition: {
    type: String,
    default: 'middle',
    validator: value => ['middle', 'top', 'bottom'].includes(value)
  },

  checkValueError: {
    type: Boolean,
    default: true
  },

  listWrapperClass: {
    type: String,
    default: ''
  },

  // if listLimit is more than one it show view more
  listLimit: {
    type: Number,
    default: 0
  },

  noBgSelectionBox: {
    type: Boolean,
    default: false
  }
})

const $attrs = useAttrs()

const emit = defineEmits([
  'update:modelValue',
  'update'
])

const _ID = $attrs.id || generateUID($attrs.name)

const currValue = proxyModel(props, 'modelValue')
const tooltipWrapper = ref()

const showError = computed(() => (!!props.errorMessage && (props.checkValueError ? _isEmpty(currValue.value) : true) && props.errorTooltip))

const showMore = ref(false)

const optionArr = computed(() => {
  if (props.listLimit === 0) {
    return props.options
  }

  const options = JSON.parse(JSON.stringify(props.options))
  const limit = !showMore.value
    ? props?.listLimit
    : 0

  return limit
    ? options.filter((_, index) => limit >= (index + 1))
    : options
})

watch(() => props.modelValue, () => {
  emit('update', currValue.value)
}, { deep: true })

onMounted(async () => {
  await nextTick()

  if (props.modelValue) { 
    currValue.value = props.modelValue
  }
})

const checkboxTooltipOpen = ref()
const checkboxMap = (() => {
  const list = !_isEmpty(props.options)
    ? props.options.reduce((acc, curr, index) => {
      const temp = curr?.info
        ? Object.assign(acc, {
          [`options-${index}`]: {
            show: false,
            tipX: props.switchIcon ? 'left' : 'right',
            tipY: props.switchIcon ? 'top' : 'bottom'
          }
        })
        : Object.assign(acc, {})

      return temp
    }, {})
    : {}

  return reactive(list)
})()

const component = computed(() => {
  const wrapper = (() => {
    let temp = ''

    if (props.inline) {
      temp = 'flex flex-col sm:flex-row sm:flex-wrap'
    } else {
      temp = `grid grid-cols-${props.mobileCols} md:grid-cols-${props.cols} ${props.noBox ? 'sm:gap-2' : 'sm:gap-4'}`
    }

    return `gap-2 ${temp}`
  })()

  const display = (() => {
    let style = getKey(props.direction, {
      col: 'justify-items-center',
      row: (() => {
        if (props.ticker === 'icon') {
          return 'grid-cols-[2.5rem,auto]'
        }

        if (props.ticker === 'none') {
          return '!flex items-center justify-start'
        }

        return removeClassExtras(`
          ${props.noBox ? '' : 'min-h-12'}
          ${(
            props.switchIcon
              ? 'grid-cols-[auto,1.5rem]'
              : 'grid-cols-[1.5rem,auto]'
          )}
        `)
      })()
    })

    style += ` grid ${props.inline && 'sm:inline-grid'}`

    return style
  })()

  const active = getKey(props.theme, {
    primary: 'peer-checked:bg-primary',
    secondary: 'peer-checked:bg-dark',
    tertiary: 'peer-checked:bg-white peer-checked:border-primary',
    light: 'peer-checked:bg-white peer-checked:border-none border-none'
  })

  const tickerPosition = getKey(props.tickerPosition, {
    middle: 'place-self-center',
    top: 'place-self-start',
    bottom: 'place-self-end'
  })

  const position = getKey(props.switchIcon, {
    true: {
      box: props.direction === 'row'
        ? 'row-start-1'
        : 'row-start-3',
      label: props.direction === 'row'
        ? 'row-start-1 col-start-1'
        : 'row-start-2',
      icon: 'row-start-1 col-start-1'
    },
    false: {
      box: props.direction === 'row'
        ? `row-start-1 col-start-1 ${tickerPosition}`
        : '',
      label: props.direction === 'row' && props.ticker !== 'none'
        ? 'row-start-1 col-start-2'
        : '',
      icon: props.direction === 'row' && props.ticker !== 'none'
        ? 'col-start-1 col-end-3 row-start-1 justify-end self-center w-full'
        : ''
    }
  })

  const padding = getKey(props.direction, {
    row: props.noBox ? '' : 'px-4 py-3',
    col: 'p-4 min-w-[9.25rem]'
  })

  const ticker = getKey(props.ticker, {
    circle: 'rounded-full before:rounded-full',
    square: 'rounded-[0.25rem] before:rounded-[0.25rem]',
    icon: '!border-none'
  })

  const tickerSize = (() => {
    const size = (() => {
      if (props.size === 'xs') {
        return '1rem'
      }

      if (props.noBox) {
        return '1.25rem'
      }

      return '1.375rem'
    })()

    const box = getKey(size, {
      '1rem': 'exact-wh-[1rem]',
      '1.25rem': 'exact-wh-[1.25rem]',
      '1.375rem': 'exact-wh-[1.375rem]'
    })

    const check = getKey(size, {
      '1rem': 'before:w-[1rem] before:h-[1rem]',
      '1.25rem': 'before:w-[1.25rem] before:h-[1.25rem]',
      '1.375rem': 'before:w-[1.375rem] before:h-[1.375rem]'
    })

    return `${box} ${check}`
  })()

  const label = `${props.activeTextBold ? 'peer-checked:font-bold' : ''} ${props.theme === 'secondary' ? 'peer-checked:text-white' : ''}`

  return {
    wrapper: removeClassExtras(wrapper),
    display: removeClassExtras(display),
    active,
    position,
    padding,
    ticker,
    tickerSize,
    label
  }
})

/**
 * closes previous tooltip
 * if `infoShowMultiple` is false
 */

watch(checkboxTooltipOpen, async (curr, prev) => {
  await nextTick()
  if (
    !props.infoShowMultiple &&
    checkboxMap[prev]
  ) {
    checkboxMap[prev].show = false
  }
})

function handleId (label) {
  if (!label) {
    return
  }

  const newId = removeHtmlString(label)
    ? removeHtmlString(label)
      .replace(/[\r\n]/gm, '')
      .replace(/\s+/gm, ' ').trim()
    : ''

  return strToKebabCase(`${_ID}${newId}`)
}

function handleInputName (option) {
  if (props.type === 'radio') {
    if (!props.name) {
      // eslint-disable-next-line no-console
      console.error('Name prop is required for radio type.')
      return
    }

    return props.name
  }

  return removeHtmlString(option?.name || option?.label || option)
    .replace(/[\r\n]/gm, '')
    .replace(/\s+/gm, ' ').trim()
}

function handleSelectOption (option) {
  try {
    if (option.disabled) {
      return
    }

    if (props.type === 'checkbox') {
      if (!Array.isArray(currValue.value)) {
        currValue.value = []
      }

      const findIndex = currValue.value.indexOf(option[props.dataValue] || option)

      if (findIndex === -1) {
        currValue.value.push(option[props.dataValue] || option)
      } else {
        currValue.value.splice(findIndex, 1)
      }
    } else {
      currValue.value = option[props.dataValue] || option
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

function isSelected (option) {
  const value = (option[props.dataValue] || option)

  if (props.type === 'radio') {
    return `${currValue.value}`.toLowerCase() === `${value}`.toLowerCase()
  }

  if (props.type === 'checkbox') {
    if (_isEmpty(currValue.value)) {
      return false
    }

    const currList = currValue.value.map(item => item?.[props.dataValue] || item)

    return currList.includes(value)
  }
}

function handleInfoColors (option) {
  if (props.theme === 'tertiary') {
    return '#193560'
  }

  return isSelected(option)
    ? '#ffffff'
    : '#193560'
}

function handleIconMargin (option) {
  let row = 'mx-4'

  if (option && (option.info && !option.icon)) {
    row = props.switchIcon ? 'ml-9 mr-4' : 'ml-4 mr-9'
  } else if (option && (!option.info && option.icon)) {
    row = props.switchIcon ? 'ml-[3.4375rem] mr-4' : 'ml-4 mr-[3.4375rem]'
  }

  if (props.noBox) {
    row = props.noBoxGap || 'mx-[0.625rem]'
  }

  if (props.size === 'xs') {
    row = 'mx-[0.2rem]'
  }

  if (props.ticker === 'none') {
    row = 'mx-0'
  }

  return getKey(props.direction, {
    row,
    col: option && (option.info || option.icon) ? 'mt-4 mb-2' : 'mt-2'
  })
}

/**
 * handle show/hide tooltip on click
 * check, and calculate tooltip position
 */
async function handleShowTooltip ($event, index, status = true) {
  const infoAction = props.infoShowAction
  const target = getKey($event.type, {
    click: 'click',
    mouseover: 'hover',
    mouseout: 'hover'
  })

  if (infoAction !== target) {
    return
  }

  checkboxTooltipOpen.value = `options-${index}`

  const config = checkboxMap[`options-${index}`]

  if (infoAction === 'click') {
    config.show = !config.show
  } else if (infoAction === 'hover') {
    config.show = status
  }

  await nextTick()

  const wrapper = tooltipWrapper.value?.[index]
  const inner = wrapper?.children?.[0]
  const svg = inner?.children?.[0]
  const tooltip = inner?.children?.[1]

  if (!tooltip) {
    return
  }

  const margin = {
    top: 5,
    bottom: 15
  }

  tooltip.style.top = `-${tooltip.clientHeight + margin.top}px`
  checkboxMap.tipY = 'bottom'

  const svgRect = svg.getBoundingClientRect()
  const tooltipRect = tooltip.getBoundingClientRect()

  if (tooltipRect.y < 0 && svg) {
    tooltip.style.top = `${svgRect.height + margin.bottom}px`
    checkboxMap.tipY = 'top'
  }
}

function handleTooltipPosition () {
  if (props.direction === 'row') {
    return props.switchIcon
      ? '-left-[0.625rem]'
      : '-right-[0.625rem]'
  }

  return 'left-1/2 -translate-x-[50%]'
}
</script>
